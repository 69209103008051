import { getInputsFromJSON } from '@/utils/formMetaCreators';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import AppRenderer from '../../components/AppRenderer';
import { toast } from 'react-toastify';
import axios from 'axios';
import Head from 'next/head';
import { forgeDecrypt, forgeEncrypt } from '@/utils/cipherHelpers';
import { useRouter } from 'next/router';
import parse from 'html-react-parser';
import { getUserId } from '@/utils/userIdUtil';
import { EVENT_IDS } from '@/constants/enums/events';
import { useDispatch } from 'react-redux';
import { updateChatbotAppData } from '@/redux/slices/chatbotApp/chatbotAppSlice';
import ChatAppInterface from '@/components/Editor/ChatAppInterface';
import { useAppUser } from '@/contexts/AppUserContext';
import AppFooter from '@/components/AppFooter';
import * as Sentry from '@sentry/nextjs';
import { HiLockClosed } from 'react-icons/hi2';
import { BiLoaderCircle } from 'react-icons/bi';

const NewAppPage = ({ appData }) => {
  const router = useRouter();
  const { auth0User, isLoading } = useAppUser();

  const [formAppData, setFormAppData] = useState(appData);
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [initialValues, setInitialValues] = useState({});
  const [inputs, setInputs] = useState([]);
  const [validationSchema, setValidationSchema] = useState({});
  const [initialOutput, setInitialOutput] = useState('');
  const [appSchema, setAppSchema] = useState({});
  const [showHubbleBranding, setShowHubbleBranding] = useState(false);
  const [isCaptured, setIsCaptured] = useState(false);

  const [password, setPassword] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      const formResponse = await axios.get(
        `${process.env.NEXT_PUBLIC_HUBBLE_API_BASE_URL}/password_protected_schema/${formAppData.id}`,
        {
          params: {
            schema_password: password,
          },
        }
      );

      if (formResponse.status === 200) {
        setFormAppData(formResponse.data.data);
        setPassword('');
        setIsAuthenticated(true);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error('Incorrect Password', {
        className: 'toast-message-error',
      });
    }
  };

  useEffect(() => {
    const capturePageVisitEvent = async () => {
      await axios.post('/api/supabase/captureEvent', {
        user_id: await getUserId(auth0User?.email),
        event_type: EVENT_IDS.APP_VISIT,
        route: `/apps/${router.query?.appId}`,
        app_id: forgeDecrypt(router.query?.appId),
        metadata: {},
      });
    };

    if (
      router.pathname === '/apps/[appId]' &&
      router.query?.appId &&
      !isLoading &&
      !isCaptured
    ) {
      setIsCaptured((prev) => {
        if (prev) return true;
        else {
          capturePageVisitEvent();
          return true;
        }
      });
    }
  }, [
    isCaptured,
    auth0User?.email,
    router.query?.appId,
    isLoading,
    router.pathname,
  ]);

  useEffect(() => {
    if (formAppData?.frontend_schema?.rendererCompatibleSchema) {
      const extractedAppSchema =
        formAppData?.frontend_schema?.rendererCompatibleSchema;
      try {
        let processedData = null;
        if (extractedAppSchema?.children?.length > 1)
          processedData = getInputsFromJSON(extractedAppSchema?.children);
        else
          processedData = getInputsFromJSON(
            extractedAppSchema?.children?.[0]?.children
          );

        // // const processedData = getInputsFromJSON(userProvidedSchema)
        const { initialValues, inputs, validationSchema } = processedData;
        setInitialValues(initialValues);
        setInputs(inputs);
        setValidationSchema(validationSchema);
        setAppSchema(extractedAppSchema);

        // setRootContainer(rootContainerData)
      } catch (err) {
        Sentry.captureEvent({
          message: 'An error occured in /pages/apps/[appId].js at line 124',
          level: 'error',
          extra: JSON.stringify(err),
        });
        toast.error(
          'We experienced an issue loading the app. Please try again.',
          {
            className: 'toast-message-error',
          }
        );
      }
    }
  }, [formAppData]);

  useEffect(() => {
    const fetchPublicData = async () => {
      try {
        const quotaResponse = await axios.get(
          `${process.env.NEXT_PUBLIC_HUBBLE_API_BASE_URL}/public_user/${formAppData.user_id}`
        );
        if (quotaResponse?.data?.remaining_app_executions <= 50)
          setShowHubbleBranding(true);
      } catch (err) {
        Sentry.captureEvent({
          message: 'An error occured in /pages/apps/[appId].js at line 149',
          level: 'error',
          extra: JSON.stringify(err),
        });
      }
    };
    if (formAppData) fetchPublicData();
  }, [formAppData]);

  useEffect(() => {
    const retrieveSavedOutput = async () => {
      try {
        const savedOutputResponse = await axios.post(
          '/api/supabase/fetchOutput',
          {
            unique_id: router?.query?.output,
            environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
          }
        );
        setInitialOutput(
          parse(savedOutputResponse?.data?.[0]?.output).substring(0, 100)
        );
      } catch (err) {
        Sentry.captureEvent({
          message: 'An error occured in /pages/apps/[appId].js at line 174',
          level: 'error',
          extra: JSON.stringify(err),
        });
      }
    };
    if (router?.query?.output) retrieveSavedOutput();
  }, [router?.query?.output]);

  if (formAppData?.password_protection && !isAuthenticated)
    return (
      <div className="bg-[#F2F2F2] w-screen justify-center h-full items-center min-h-screen flex flex-col grow">
        <form
          onSubmit={handleSubmit}
          className="bg-[#F2F2F2] p-6 rounded-lg w-fit flex flex-col space-y-4"
        >
          <div className="flex flex-row items-center justify-center space-x-2 text-2xl font-medium mb-4 text-gray-400">
            <HiLockClosed className="h-20 w-20 border-2 text-gray-400 rounded-full p-5" />
            {/*<h1>This app is password-protected.</h1>*/}
          </div>

          <div>
            {/*<label className="block text-white mb-2" htmlFor="password">
              Password
    </label>*/}
            <input
              type="password"
              id="password"
              className="w-72 px-3 py-2 text-gray-300 border-gray-400 rounded-md h-12 focus:outline-none"
              onChange={(event) => setPassword(event.target.value)}
              value={password}
              placeholder="Password"
            />
          </div>
          <button
            type="submit"
            className="py-2.5 mt-2 bg-blue-500 text-white rounded shadow hover:bg-blue-600 cursor-pointer text-center text-md flex flex-row items-center justify-center space-x-2"
          >
            {loading ? (
              <BiLoaderCircle className="w-4 h-4 animate-spin" />
            ) : (
              <HiLockClosed className="w-4 h-4" />
            )}
            <span>Submit</span>
          </button>
        </form>
      </div>
    );
  if (!appSchema?.children) return 'Loading';

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/icon.svg" />

        <title>{`${appData?.title} | Powered by Rubber`}</title>
        <meta name="title" content={`${appData?.title} | Powered by Rubber`} />
        <meta
          name="description"
          content={
            appData?.description ||
            'Create your own AI-powered apps in minutes.'
          }
        />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.rubber.ai" />
        <meta
          property="og:title"
          content={`${appData?.title} | Powered by Rubber`}
        />
        <meta
          property="og:description"
          content={
            appData?.description ||
            'Create your own AI-powered apps in minutes.'
          }
        />
        <meta
          name="image"
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_HOST_URL}/api/og?title=${
            appData?.title
          }&description=${appData.description}&app_url=${
            process.env.NEXT_PUBLIC_HOST_URL
          }/apps/${forgeEncrypt(appData.id.toString())}&homepage_url=${
            process.env.NEXT_PUBLIC_HOST_URL
          }&output=${initialOutput}`}
        />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.rubber.ai" />
        <meta
          property="twitter:title"
          content={`${appData?.title} | Powered by Rubber`}
        />
        <meta
          property="twitter:description"
          content={
            appData?.description ||
            'Create your own AI-powered apps in minutes.'
          }
        />
        <meta
          property="twitter:image"
          content={`${process.env.NEXT_PUBLIC_HOST_URL}/api/og?title=${
            appData?.title
          }&description=${appData.description}&app_url=${
            process.env.NEXT_PUBLIC_HOST_URL
          }/apps/${forgeEncrypt(appData.id.toString())}&homepage_url=${
            process.env.NEXT_PUBLIC_HOST_URL
          }&output=${initialOutput}`}
        />
        <script
          src="https://cdn.jsdelivr.net/npm/iframe-resizer@4.3.6/js/iframeResizer.contentWindow.min.js"
          defer
        ></script>
      </Head>

      {appSchema.children.length > 1 ? (
        <div className="bg-[#F2F2F2] w-screen justify-center h-full items-center min-h-screen flex flex-col grow">
          <div className="md:w-2/3 w-full flex flex-col grow font-Inter mb-16">
            <AppRenderer
              initialValues={initialValues}
              inputs={inputs}
              validationSchema={validationSchema}
              appSchema={appSchema}
              rawSchema={formAppData}
              showHubbleBranding={showHubbleBranding}
            />
            <div className="fixed right-6 bottom-4 shadow-lg">
              {router.pathname === '/apps/[appId]' && showHubbleBranding && (
                <AppFooter />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            ...appSchema.props.styles,
            borderRadius: '0px',
          }}
          className="mx-0"
        >
          <div
            style={{
              ...appSchema.children[0].props.styles,
              marginBottom: '2rem',
              borderRadius: '24px',
              paddingTop: '3rem',
              paddingLeft: '3rem',
              paddingBottom: '3rem',
              paddingRight: '3rem',
              marginTop: '2rem',
              minWidth: '75%',
              // width: '85%',
              maxWidth: '100%',
            }}
          >
            <AppRenderer
              initialValues={initialValues}
              inputs={inputs}
              validationSchema={validationSchema}
              appSchema={appSchema}
              rawSchema={formAppData}
              showHubbleBranding={showHubbleBranding}
            />
            <div className="fixed right-6 bottom-4 shadow-lg">
              {router.pathname === '/apps/[appId]' && showHubbleBranding && (
                <AppFooter />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const AppPage = ({ appData }) => {
  const dispatch = useDispatch();

  const ogImageURL = appData?.frontend_schema?.ogImageURL;

  if (appData?.frontend_schema?.schemaType === 'CHAT') {
    dispatch(updateChatbotAppData(appData));
    return (
      <>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="/icon.svg" />

          <title>{`${appData?.title} | Powered by Rubber`}</title>
          <meta
            name="title"
            content={`${appData?.title} | Powered by Rubber`}
          />
          <meta
            name="description"
            content={
              appData?.description ||
              'Create your own AI-powered apps in minutes.'
            }
          />

          {/* <!-- Open Graph / Facebook --> */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.rubber.ai" />
          <meta
            property="og:title"
            content={`${appData?.title} | Powered by Rubber`}
          />
          <meta
            property="og:description"
            content={
              appData?.description ||
              'Create your own AI-powered apps in minutes.'
            }
          />
          <meta
            name="image"
            property="og:image"
            content={
              ogImageURL
                ? ogImageURL
                : `${process.env.NEXT_PUBLIC_HOST_URL}/api/og?title=${
                    appData?.title
                  }&description=${appData.description}&app_url=${
                    process.env.NEXT_PUBLIC_HOST_URL
                  }/apps/${forgeEncrypt(appData.id.toString())}&homepage_url=${
                    process.env.NEXT_PUBLIC_HOST_URL
                  }`
            }
          />

          {/* <!-- Twitter --> */}
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://www.rubber.ai" />
          <meta
            property="twitter:title"
            content={`${appData?.title} | Powered by Rubber`}
          />
          <meta
            property="twitter:description"
            content={
              appData?.description ||
              'Create your own AI-powered apps in minutes.'
            }
          />
          <meta
            property="twitter:image"
            content={
              ogImageURL
                ? ogImageURL
                : `${process.env.NEXT_PUBLIC_HOST_URL}/api/og?title=${
                    appData?.title
                  }&description=${appData.description}&app_url=${
                    process.env.NEXT_PUBLIC_HOST_URL
                  }/apps/${forgeEncrypt(appData.id.toString())}&homepage_url=${
                    process.env.NEXT_PUBLIC_HOST_URL
                  }`
            }
          />
        </Head>
        <div
          className="w-full h-screen"
          style={{
            backgroundColor:
              appData?.frontend_schema?.styles?.pageBackgroundColor,
          }}
        >
          <div className="w-auto m-auto min-w-[50%] justify-center max-w-full items-center h-full">
            <div
              className="flex flex-row p-4 w-full h-full justify-center items-center"
              style={{
                backgroundColor:
                  appData?.frontend_schema?.styles?.pageBackgroundColor ||
                  '#ffffff',
              }}
            >
              <ChatAppInterface />
            </div>
          </div>
        </div>
      </>
    );
  } else if (appData) return <NewAppPage appData={appData} />;
  return null;
};

export const getStaticProps = async (context) => {
  try {
    const appId = context?.params?.appId;
    if (!parseInt(forgeDecrypt(appId), 10)) throw new Error('Invalid App ID');

    const appDataResponse = await axios.get(
      `${process.env.NEXT_PUBLIC_HUBBLE_API_BASE_URL}/schemas?id=${forgeDecrypt(
        appId
      )}`
    );

    if (!appDataResponse.data.data) {
      return {
        notFound: true,
      };
    }

    const appData = appDataResponse?.data?.data?.[0];
    return { props: { appData: appData || {} }, revalidate: 30 };
  } catch (err) {
    // Add Better Sentry Logging
    Sentry.captureEvent({
      message:
        'Error generating app page. Error in : /apps/[appId].js/getStaticProps',
      level: 'error',
      extra: JSON.stringify(err),
    });
    return {
      notFound: true,
    };
  }
};

export const getStaticPaths = async () => {
  const allAppsData = await axios.get(
    `${process.env.NEXT_PUBLIC_HUBBLE_API_BASE_URL}/schemas`,
    {
      params: {
        limit: 1000,
        order_dir: 'desc',
        order_by: 'created_at',
      },
    }
  );

  const encryptedAppIds = allAppsData.data.data?.map((app) => {
    return {
      params: {
        appId: forgeEncrypt(app.id.toString()),
      },
    };
  });

  return {
    paths: encryptedAppIds || [],
    fallback: true,
  };
};

export default AppPage;
