import React from 'react';

const CustomLink = ({ children, href }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="cursor-pointer text-blue-600 hover:text-blue-800"
    >
      {children}
    </a>
  );
};

export default CustomLink;
