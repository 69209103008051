import forge from 'node-forge';
import CryptoJS from 'crypto-js';

export const encrypt = (plaintext) => {
  var encrypted = CryptoJS.AES.encrypt(
    plaintext,
    process.env.NEXT_PUBLIC_CIPHER_KEY,
    {
      iv: process.env.NEXT_PUBLIC_CIPHER_IV,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    }
  );

  let buff = new Buffer(encrypted.toString());
  let base64data = buff.toString('base64');

  return base64data;
};

export const decrypt = (encryptedText) => {
  // create a buffer
  const buff = Buffer.from(encryptedText, 'base64');

  // decode buffer as UTF-8
  const encryptedAppId = buff.toString('utf-8');

  var decrypted = CryptoJS.AES.decrypt(
    encryptedAppId,
    process.env.NEXT_PUBLIC_CIPHER_KEY,
    {
      iv: process.env.NEXT_PUBLIC_CIPHER_IV,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    }
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const forgeEncrypt = (plainText) => {
  // encrypt some bytes using CBC mode
  // (other modes include: CFB, OFB, CTR, and GCM)
  var cipher = forge.cipher.createCipher(
    'AES-CBC',
    process.env.NEXT_PUBLIC_CIPHER_KEY
  );
  cipher.start({ iv: process.env.NEXT_PUBLIC_CIPHER_IV });
  cipher.update(forge.util.createBuffer(plainText));
  cipher.finish();
  var encrypted = cipher.output;
  return encrypted.toHex();
};

export const forgeDecrypt = (hexText) => {
  // decrypt some bytes using CBC mode
  // (other modes include: CFB, OFB, CTR, and GCM)
  var decipher = forge.cipher.createDecipher(
    'AES-CBC',
    process.env.NEXT_PUBLIC_CIPHER_KEY
  );
  decipher.start({ iv: process.env.NEXT_PUBLIC_CIPHER_IV });
  decipher.update(forge.util.createBuffer(forge.util.hexToBytes(hexText)));
  decipher.finish();
  // outputs decrypted hex
  return decipher.output.data;
};
