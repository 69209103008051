import {
  selectChatbotActiveAppId,
  selectChatbotAppName,
  selectChatbotBotImagePreview,
  selectChatbotBotImageURL,
  selectChatbotBotMessageBackgroundColor,
  selectChatbotBotMessageFontColor,
  selectChatbotChatBodyBackgroundColor,
  selectChatbotCtaTitle,
  selectChatbotCtaURL,
  selectChatbotHeaderBackgroundColor,
  selectChatbotHeaderFontColor,
  selectChatbotShowCTA,
  selectChatbotUserMessageBackgroundColor,
  selectChatbotUserMessageFontColor,
  selectChatbotWelcomeMessage,
} from '@/redux/slices/chatbotApp/chatbotAppSlice';
import rehypeSlug from 'rehype-slug';
import rehypeRaw from 'rehype-raw';

import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useAppUser } from '@/contexts/AppUserContext';
import Image from 'next/image';
import { HiUserCircle } from 'react-icons/hi';
import { nanoid } from 'nanoid';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CustomLink from '../OutputComponents/CustomLink';
import Link from 'next/link';

const UserMessageBlock = ({ message, showInputField }) => {
  const { auth0User } = useAppUser();
  const userMessageBackgroundColor = useSelector(
    selectChatbotUserMessageBackgroundColor
  );
  const userMessageFontColor = useSelector(selectChatbotUserMessageFontColor);

  const formatMessage = (text) => {
    return text.replace(/\n/g, '<br />');
  };

  return (
    <div className="flex flex-row items-center justify-center space-x-2 mb-4">
      <div
        className="w-10/12 ml-auto rounded-lg"
        style={{
          backgroundColor: `${userMessageBackgroundColor || '#ffffff'}`,
          color: `${userMessageFontColor || '#000000'}`,
        }}
      >
        <div className="px-4 py-4 w-full break-words text-[16px]">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeSlug, rehypeRaw]}
            components={{ a: CustomLink }}
          >
            {formatMessage(message.text)}
          </ReactMarkdown>
        </div>
      </div>

      {auth0User?.picture && showInputField ? (
        <div className="relative mt-6 w-8 h-8">
          <Image
            src={auth0User.picture}
            className="rounded-full"
            fill
            alt="Profile Picture"
          />
        </div>
      ) : (
        <HiUserCircle className="rounded-full w-8 h-8" />
      )}
    </div>
  );
};

const BotMessageBlock = ({ message }) => {
  const botMessageBackgroundColor = useSelector(
    selectChatbotBotMessageBackgroundColor
  );
  const botImagePreview = useSelector(selectChatbotBotImagePreview);
  const botImageURL = useSelector(selectChatbotBotImageURL);

  const botMessageFontColor = useSelector(selectChatbotBotMessageFontColor);

  const formatMessage = (text) => {
    return text.replace(/\n/g, '<br />');
  };

  if (message.typing) return <div className="typing-animation">...</div>;
  return (
    <div className="flex flex-row items-end space-x-2">
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src={botImagePreview || botImageURL}
        className="rounded-full h-8 w-8 mb-4 cursor-pointer"
        alt=""
      />
      <div
        className="mb-4 w-10/12 rounded-lg text-[16px]"
        style={{
          backgroundColor: `${botMessageBackgroundColor || 'white'}`,
          color: `${botMessageFontColor || 'black'}`,
        }}
      >
        <div
          className="px-4 py-4 break-words prose"
          style={{
            color: `${botMessageFontColor || 'black'}`,
          }}
        >
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeSlug, rehypeRaw]}
            components={{ a: CustomLink }}
          >
            {formatMessage(message.text)}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

const ChatAppInterface = ({ pastMessages = [], showInputField = true }) => {
  const [messages, setMessages] = useState(pastMessages || []);
  const [inputMessage, setInputMessage] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [chatSessionId, setChatSessionId] = useState(nanoid(36));
  const [isTyping, setIsTyping] = useState(false);
  const [inputRows, setInputRows] = useState(1);

  const messagesContainerRef = useRef(null);

  const schemaId = useSelector(selectChatbotActiveAppId);
  const appName = useSelector(selectChatbotAppName);
  const headerBackgroundColor = useSelector(selectChatbotHeaderBackgroundColor);
  const headerFontColor = useSelector(selectChatbotHeaderFontColor);
  const chatBodyBackgroundColor = useSelector(
    selectChatbotChatBodyBackgroundColor
  );

  const welcomeMessage = useSelector(selectChatbotWelcomeMessage);

  const showCTA = useSelector(selectChatbotShowCTA);
  const ctaTitle = useSelector(selectChatbotCtaTitle);
  const ctaURL = useSelector(selectChatbotCtaURL);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage) return;

    setInputRows(1);
    setInputMessage('');
    // Add the user message to the messages list
    setMessages([...messages, { text: inputMessage, sender: 'user' }]);

    if (schemaId) {
      setIsTyping(true);
      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_HUBBLE_API_BASE_URL}/execute_schema`,
        {
          schema_id: schemaId,
          chat_user_message: inputMessage,
          chat_session_id: chatSessionId,
        }
      );

      if (response.status === 200) {
        // Update the messages list with the API response
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: response.data.node_outputs[0].output_data, sender: 'bot' },
        ]);
      } else {
        // Update the messages list with the API response
        setMessages((prevMessages) => [
          ...prevMessages.slice(0, -1),
          { text: 'Cannot process your message!', sender: 'bot' },
        ]);
      }
      setIsTyping(false);
    } else {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: 'To test your chatbot, please save your app first.',
          sender: 'bot',
        },
      ]);
    }
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      const container = messagesContainerRef.current;
      container.scrollTop = container.scrollHeight;
    }
  };

  const handleInputChange = (e) => {
    // const target = e.target;
    // setInputMessage(target.value);

    // // Calculate the number of rows based on the scrollHeight
    // // target.style.height = 'auto';
    // const rows = Math.min(Math.floor(target.scrollHeight / 16), 6);
    // setInputRows(rows);

    const target = e.target;
    setInputMessage(target.value);

    // Reset the height to 'auto' to adjust the height based on the content
    target.style.height = 'auto';

    // Calculate the padding
    const style = window.getComputedStyle(target);
    const paddingTop = parseFloat(style.paddingTop);
    const paddingBottom = parseFloat(style.paddingBottom);

    // Calculate the number of rows based on the adjusted scrollHeight
    const adjustedScrollHeight =
      target.scrollHeight - paddingTop - paddingBottom;

    const rows = Math.min(Math.floor(adjustedScrollHeight / 20), 6);
    setInputRows(rows);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (pastMessages.length > 0)
      setMessages(() => [
        {
          text:
            welcomeMessage || `Hi there! I am bot. How can I help you today?`,
          sender: 'bot',
        },
        ...pastMessages,
      ]);

    // We are not going to add the welcomeMessage to the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pastMessages]);

  // This seperate block is needed to show the welcome message first.
  useEffect(() => {
    setMessages((prevMessages) => {
      const oldMessages = [...prevMessages];
      oldMessages.shift();
      return [
        {
          text:
            welcomeMessage || `Hi there! I am bot. How can I help you today?`,
          sender: 'bot',
        },
        ...oldMessages,
      ];
    });
  }, [welcomeMessage]);

  return (
    <div
      className={`flex flex-col h-full rounded-t-3xl w-full ${
        showInputField ? 'md:w-[640px]' : ''
      }`}
    >
      {/* Header */}
      {showInputField && (
        <div
          className="flex flex-row items-start px-8 py-6 border rounded-t-3xl"
          style={{
            backgroundColor: `${headerBackgroundColor || '#ffffff'}`,
            color: `${headerFontColor || '#000000'}`,
          }}
        >
          <h1 className="text-xl font-medium leading-7 w-full">
            {appName || 'Untitled'}
          </h1>
        </div>
      )}

      {/* Messages / Body */}
      <div
        ref={messagesContainerRef}
        className="flex flex-col overflow-y-auto w-full grow px-4 py-4"
        style={{ backgroundColor: `${chatBodyBackgroundColor || '#F2F2F2'}` }}
      >
        {messages?.map((message, index) => (
          <div
            key={index}
            className={`mb-4 w-full ${
              message.sender === 'user' ? 'ml-auto' : ''
            }`}
          >
            {message.sender === 'user' ? (
              <UserMessageBlock
                message={message}
                key={index}
                showInputField={showInputField}
              />
            ) : (
              <BotMessageBlock message={message} key={index} />
            )}
          </div>
        ))}

        {/* Typing Animation */}
        {isTyping && (
          <div className="typing-indicator">
            <div></div>
            <div></div>
            <div></div>
            {/*<p>Typing</p>*/}
          </div>
        )}
      </div>

      {/* Input */}
      {showInputField && (
        <div
          className="flex border-b w-full px-4 pb-4 rounded-b-3xl flex-col pt-2 items-center space-y-4"
          style={{
            backgroundColor: `${chatBodyBackgroundColor || '#F2F2F2'}`,
          }}
        >
          {showCTA && (
            <Link
              className="px-5 py-2.5 font-medium text-sm rounded-xl cursor-pointer"
              style={{
                backgroundColor: `${headerBackgroundColor || '#ffffff'}`,
                color: `${headerFontColor || '#000000'}`,
              }}
              href={ctaURL || '#'}
              target="_blank"
            >
              {ctaTitle || 'Click Here'}
            </Link>
          )}
          <div className="flex w-full">
            <form onSubmit={handleSubmit} className="w-full">
              <div className="flex w-full bg-white rounded-3xl">
                <textarea
                  rows={inputRows}
                  className="w-full font-sm p-2 border-none rounded-3xl mr-2 px-5 py-4 focus:outline-none focus:ring-0 transition-all duration-500 ease-in-out outline-none resize-none"
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                  // onChange={handleInputChange}
                  onFocus={() => setInputRows(3)}
                  onBlur={() => setInputRows(1)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleSubmit(e);
                    }
                  }}
                  placeholder="Type Your Message"
                />
                <div className="justify-end items-center flex pr-3">
                  <button
                    type="submit"
                    className="bg-[#ECF5FF] cursor-pointer rounded-full text-white p-3"
                  >
                    <Image
                      src="/assets/icons/SendIcon.svg"
                      className="cursor-pointer"
                      height="0"
                      width="24"
                      alt="Edit Icon"
                    />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatAppInterface;
